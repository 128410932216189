import { useTranslation } from 'react-i18next';
import {
    dispatchAndSaveToLocalStorage,
    saveOrUpdateQuote,
    setValidMountingSystems,
    updateProduct,
    updateQuoteName,
} from '../../features/designerQuote/designerQuoteActions';
import { closeModal, openModal } from '../../features/modals/modalActions';
import { newNotification } from '../../features/notifications/notificationsActions';
import {
    allSurfaceDetails,
    allSurfaceHaveDimensions,
    createDefaultQuoteName,
} from '../functions';
import { ModalButton, ProductTypes, UserRoles } from '../types';
import useDesignerQuote from './useDesignerQuote';
import useProductAvailability from './useProductAvailability';
import useUserRole from './useUserRole';
import { useAppDispatch } from './index';
import { useEffect, useState } from 'react';

export default function useHandleSave(
    finishCallback?: (success: boolean) => void,
    startCallback?: (success: boolean) => void
) {
    const dispatch = useAppDispatch();
    const quote = useDesignerQuote();
    const products = quote.salesforce.Products;
    const { t } = useTranslation('notifications');
    const role = useUserRole();
    const {
        isAllProductsActive,
        isAllProductsVisible,
        isAllSalesActiveAndVisible,
        isAllNonSalesActiveAndVisible,
    } = useProductAvailability();

    // Handlers for Sales Save Modal
    const handleFormChange = (event: any, data: any) => {
        dispatch(
            dispatchAndSaveToLocalStorage(
                updateQuoteName({
                    quoteName: data.value === '' ? undefined : data.value,
                })
            )
        );
    };

    const handleSalesSave = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        dispatch(saveOrUpdateQuote(() => {}, false, false));
        dispatch(closeModal());
    };

    enum PlanningChecks {
        allProductVisible = 'allProductVisible',
        electricalMaterialAdded = 'electricalMaterialAdded',
        hemCompatibleWithInverters = 'hemCompatibleWithInverters',
    }
    const modalNames: { [key in PlanningChecks]: string } = {
        allProductVisible: 'modals:invisibleProductsPlanner',
        electricalMaterialAdded: 'modals:noElectricalMaterial',
        hemCompatibleWithInverters: 'modals:hemNotCompatibleWithInverters',
    };

    const [agreements, setAgreements] = useState<
        { [key in PlanningChecks]: boolean } | null
    >(null);

    const handlePlanningSave = (shouldUpdate: boolean) => {
        startCallback && startCallback(true);
        dispatch(saveOrUpdateQuote(finishCallback, shouldUpdate, false));
        dispatch(closeModal());
        setAgreements(null);
    };

    const handleModalClose = () => {
        dispatch(closeModal());
    };

    const [shouldUpdate, setShouldUpdate] = useState(false);

    useEffect(() => {
        if (agreements === null) return;
        if (Object.values(agreements).every((value) => value)) {
            handlePlanningSave(shouldUpdate);
        } else {
            for (const [key, value] of Object.entries(agreements)) {
                if (value) continue;
                const modal = modalNames[key as PlanningChecks];
                const buttons: ModalButton[] = [
                    {
                        text: t(`${modal}.cancel`) as string,
                        type: 'negative',
                        onClick: () => {
                            if (
                                key ===
                                PlanningChecks.hemCompatibleWithInverters
                            ) {
                                setAgreements({
                                    ...agreements,
                                    [key]: true,
                                });
                            }
                            handleModalClose();
                        },
                    },
                    {
                        text: t(`${modal}.confirm`) as string,
                        type: 'positive',
                        onClick: () => {
                            if (
                                key ===
                                PlanningChecks.hemCompatibleWithInverters
                            ) {
                                dispatch(
                                    updateProduct({
                                        product: null,
                                        productType: ProductTypes.HEM_GATEWAY,
                                    })
                                );
                            }
                            setAgreements({
                                ...agreements,
                                [key]: true,
                            });
                            handleModalClose();
                        },
                    },
                ];
                if (key === PlanningChecks.hemCompatibleWithInverters) {
                    buttons.unshift({
                        text: t('modals:saveQuote.cancel') as string,
                        type: 'negative',
                        onClick: handleModalClose,
                    });
                }
                dispatch(
                    openModal({
                        modalProps: {
                            title: t(`${modal}.title`),
                            description: t(`${modal}.description`),
                            className: 'sales',
                            buttons,
                            onModalClose: handleModalClose,
                            size: 'large',
                        },
                    })
                );
                break;
            }
        }
    }, [agreements]);

    return (shouldUpdate = false) => {
        const panelAmount = products?.panels.quantity || 0;
        if (panelAmount === 0) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: t('notifications:noPanels'),
                    icon: 'edit',
                })
            );
            return;
        }
        if (!allSurfaceDetails(quote.planning)) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: t('notifications:insertAzimuthAndSlope'),
                    icon: 'edit',
                })
            );
            return;
        }

        dispatch(dispatchAndSaveToLocalStorage(setValidMountingSystems({})));

        if (role === UserRoles.sales) {
            // Sales Version: open saving modal
            if (!products?.inverter) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:noInverter'),
                        icon: 'edit',
                    })
                );
                return;
            }
            const quoteName = createDefaultQuoteName(quote);

            dispatch(
                dispatchAndSaveToLocalStorage(
                    updateQuoteName({
                        quoteName,
                    })
                )
            );

            // Has the Ability to change the products
            if (
                (!isAllProductsActive || !isAllProductsVisible) &&
                (!isAllSalesActiveAndVisible || isAllNonSalesActiveAndVisible)
            ) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:existingErrors'),
                    })
                );
                return;
            }

            dispatch(
                openModal({
                    modalProps: {
                        title: t('modals:saveQuote.title'),
                        description: t('modals:saveQuote.description'),
                        className: 'sales',
                        formInputs: {
                            quoteName: {
                                value: quoteName || '',
                                errorMessage: t(
                                    'validations:mustHaveQuoteName'
                                ),
                                inline: false,
                                validation: (val: string) => val.length > 0,
                                size: 'large',
                            },
                        },
                        formOnChange: handleFormChange,
                        buttons: [
                            {
                                text: t('modals:saveQuote.cancel') as string,
                                type: 'negative',
                                onClick: handleModalClose,
                            },
                            {
                                text: t('modals:saveQuote.confirm') as string,
                                type: 'positive',
                                onClick: handleSalesSave,
                            },
                        ],
                        onModalClose: handleModalClose,
                        size: 'large',
                    },
                })
            );
        } else {
            if (!quote.salesforce.Products?.inverter) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:noInverter'),
                        icon: 'edit',
                    })
                );
                return;
            }

            if (!allSurfaceHaveDimensions(quote.planning)) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:surfaceDimensionsMissing'),
                    })
                );
                return;
            }

            if (!products?.panels?.quantity || products?.panels.quantity <= 0) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:noPanels'),
                        icon: 'edit',
                    })
                );
                return;
            }

            const hasElectroMaterial = !!(
                products.electricalMaterial && products.electricalMaterial.id
            );

            const badHem =
                quote.salesforce.Products.hemGateway! &&
                (quote.salesforce.Products.inverter?.hemsCompatible === false ||
                    quote.salesforce.Products.secondInverter?.hemsCompatible ===
                        false);

            if (
                isAllProductsVisible &&
                isAllProductsActive &&
                hasElectroMaterial &&
                !badHem
            ) {
                handlePlanningSave(shouldUpdate);
            } else {
                setShouldUpdate(shouldUpdate);
                setAgreements({
                    allProductVisible:
                        isAllProductsVisible && isAllProductsActive,
                    electricalMaterialAdded: hasElectroMaterial,
                    hemCompatibleWithInverters: !badHem,
                });
            }
        }
    };
}
