import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debouncedGetRecommendedInverters } from '../../../api/getRecommenedInverters';
import {
    allSurfaceDetails,
    allSurfacesHavePanels,
    getSurfacesOfPlanning,
    getValidPanelsCount,
    hasSurfaceDetailsChanged,
} from '../../../common/functions';
import useEmergencyPower from '../../../common/hooks/useEmergencyPower';
import useOpportunity from '../../../common/hooks/useOpportunity';
import { InverterResponse } from '../../../common/responseTypes';
import {
    ModulePlanningData,
    PlanningData,
    ProductsState,
    ProductTypes,
    RecordType,
    RootState,
    SalesforceProductsState,
    UserRoles,
} from '../../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    setRecommendedInverters,
    setValidMountingSystems,
    updateProduct,
} from '../../../features/designerQuote/designerQuoteActions';
import { newNotification } from '../../../features/notifications/notificationsActions';
import ExpandableIcon from '../../ExpandableIcon';
import IconToggle from '../../IconToggle';
import ProductSelector from '../../ProductSelector';
import Stack from '../../Stack';
import UndoRedo from '../../UndoRedo';
import PanelToggle from '../PanelToggle';
import styles from './SalesTools.module.scss';
import { useAppDispatch } from '../../../common/hooks';
import useElectricalCabinetUpgrades from '../../../common/hooks/useElectricalCabinetUpgrades';

type Props = {
    style?: CSSProperties;
    className?: string;
    mountingColor: string;
};

const SalesTools: FunctionComponent<Props> = ({ mountingColor }) => {
    const [failNotiShown, setFailNotiShown] = useState(false);
    const [isExpanded, setIsExpanded] = useState<boolean>();
    const dispatch = useAppDispatch();
    const opportunity = useOpportunity();
    const { t } = useTranslation(['common', 'productsWizard']);

    const products = useSelector<
        RootState,
        SalesforceProductsState | undefined
    >((state) => state.designerQuote.salesforce.Products);

    const cloudProducts = useSelector<
        RootState,
        SalesforceProductsState | undefined
    >((state) => state.cloudQuote?.salesforce.Products);

    const availableProducts = useSelector<RootState, ProductsState>(
        (state) => state.products
    );

    const planning = useSelector<RootState, PlanningData | undefined>(
        (state) => state.designerQuote.planning
    );

    const recordType = useSelector<RootState, RecordType | undefined>(
        (state) => state.designerQuote.salesforce.recordType
    );

    const { addEmergencyPower, emergencyPowerPossible, toggleEmergencyPower } =
        useEmergencyPower(products);

    const [surfaces, setSurfaces] = useState(getSurfacesOfPlanning(planning!));

    const handleIconClick = useCallback(() => {
        setIsExpanded((e) => !e);
    }, []);

    useElectricalCabinetUpgrades();

    useEffect(() => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        if (surfaces.length !== newSurfaces.length) {
            setSurfaces(newSurfaces);
        }
    }, [products?.inverter, planning]);

    const getAndSetRecommendedInverters = (
        validSurfaces: ModulePlanningData[]
    ) => {
        debouncedGetRecommendedInverters({
            opportunityId: opportunity.opportunityId,
            surfaces: validSurfaces,
            panelPower: products?.panels.power || 0,
            role: UserRoles.sales,
            recordType,
        })
            .then(async (promise) => {
                let inverterResponse: InverterResponse;
                try {
                    inverterResponse = await promise;
                } catch (error) {
                    return;
                }

                dispatch(
                    setRecommendedInverters({
                        data: {
                            recommendedInverters:
                                inverterResponse.inverters[0].recommended,
                            secondRecommendedInverters:
                                inverterResponse.inverters[1]?.recommended ??
                                [],
                            annualYieldHours:
                                inverterResponse.annual_yield_hours,
                            reducedAnnualYieldHours:
                                inverterResponse.reduced_annual_yield_hours,
                        },
                    })
                );
                if (failNotiShown) {
                    dispatch(
                        newNotification({
                            type: 'success',
                            message: t('notifications:failSolved'),
                            details: t('notifications:lastestData') as string,
                        })
                    );
                    setFailNotiShown(false);
                }
            })
            .catch((prom) => {
                if (!failNotiShown && prom !== 'debounced') {
                    dispatch(
                        newNotification({
                            type: 'error',
                            message: t('notifications:failSales'),
                            details: t('notifications:tryAgainSales') as string,
                        })
                    );
                    setFailNotiShown(true);
                }
            });
    };

    useEffect(() => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        const validSurfaces = newSurfaces.filter(
            (surface) => getValidPanelsCount(surface.panels) > 0
        );

        /* Checks if all surface details are valid, and if the azimuth or slope has changed. 
        If either of these things have happened, then it triggers the API call to get the recommended inverters */
        if (
            allSurfaceDetails(planning!) &&
            validSurfaces.length > 0 &&
            hasSurfaceDetailsChanged(newSurfaces, surfaces)
        ) {
            getAndSetRecommendedInverters(validSurfaces);
            setSurfaces(newSurfaces);
        }
        dispatch(
            dispatchAndSaveToLocalStorage(
                setValidMountingSystems({ color: mountingColor })
            )
        );
    }, [planning]);

    const hemsEnabled = process.env.REACT_APP_ENABLE_HEMS === '1';
    useEffect(() => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        const validSurfaces = newSurfaces.filter(
            (surface) => getValidPanelsCount(surface.panels) > 0
        );

        allSurfacesHavePanels(newSurfaces) &&
            validSurfaces.length > 0 &&
            getAndSetRecommendedInverters(validSurfaces);
    }, [products?.panels.quantity]);
    return (
        <Stack
            orientation={'vertical'}
            className={classNames(styles.salesTools, 'top')}
        >
            <UndoRedo />
            <PanelToggle />
            <Stack
                orientation={'vertical'}
                className={classNames(styles.stack, 'gruppe')}
            >
                <ExpandableIcon
                    onIconClick={handleIconClick}
                    expanded={isExpanded}
                    icon={'panels'}
                    badgeValue={products?.panels.quantity}
                >
                    <div className={styles.lilp}>
                        {products?.panels.quantity + ' ' + t('panels')}
                    </div>
                </ExpandableIcon>

                <ExpandableIcon
                    onIconClick={handleIconClick}
                    expanded={isExpanded}
                    icon={'power'}
                    badgeValue={
                        products
                            ? (
                                  (products.panels.quantity || 0) *
                                  (products.panels.power || 0)
                              ).toLocaleString('de-DE')
                            : 0
                    }
                >
                    <div className={styles.lilp}>
                        {(products
                            ? (products.panels.quantity || 0) *
                              (products.panels.power || 0)
                            : 0) + ' Wp'}
                    </div>
                </ExpandableIcon>

                <ExpandableIcon
                    onIconClick={handleIconClick}
                    expanded={isExpanded}
                    icon={'panel'}
                >
                    <ProductSelector
                        productCategory={ProductTypes.PANEL}
                        hasEmptyOption={false}
                        className={styles.productSelector}
                    />
                </ExpandableIcon>
                <ExpandableIcon
                    onIconClick={handleIconClick}
                    expanded={isExpanded}
                    icon={'inverter'}
                    oneLine={true}
                >
                    <ProductSelector
                        productCategory={ProductTypes.INVERTER}
                        className={styles.productSelector}
                        hasEmptyOption={false}
                    />
                    {emergencyPowerPossible && (
                        <IconToggle
                            icon={'alert'}
                            className={styles.emergencyPower}
                            hoverLabel={
                                t('productsWizard:emergencyPower') as string
                            }
                            value={addEmergencyPower}
                            onClick={toggleEmergencyPower}
                        />
                    )}
                    {availableProducts.hemGateway && hemsEnabled && (
                        <IconToggle
                            icon={'hems'}
                            className={styles.hem}
                            hoverLabel={'HEM'}
                            value={!!products?.hemGateway}
                            onClick={(event, checked) => {
                                dispatch(
                                    updateProduct({
                                        productType: ProductTypes.HEM_GATEWAY,
                                        product: checked
                                            ? availableProducts.hemGateway![0]
                                            : null,
                                    })
                                );
                            }}
                        />
                    )}
                </ExpandableIcon>
                {cloudProducts && cloudProducts.secondInverter && (
                    <ExpandableIcon
                        onIconClick={handleIconClick}
                        expanded={isExpanded}
                        icon={'inverter'}
                        oneLine={true}
                    >
                        <ProductSelector
                            productCategory={ProductTypes.SECOND_INVERTER}
                            productOptionsType={ProductTypes.INVERTER}
                            className={styles.productSelector}
                            secondInstance={true}
                            hasEmptyOption={true}
                        />
                    </ExpandableIcon>
                )}
                {availableProducts.battery &&
                    availableProducts.battery.length > 0 && (
                        <ExpandableIcon
                            onIconClick={handleIconClick}
                            expanded={isExpanded}
                            icon={'battery'}
                            badgeValue={
                                products && products.battery?.power
                                    ? products.battery.power.toLocaleString(
                                          'de-DE'
                                      )
                                    : undefined
                            }
                            hasValue={!!products?.battery}
                        >
                            <ProductSelector
                                productCategory={ProductTypes.BATTERY}
                                className={styles.productSelector}
                            />
                        </ExpandableIcon>
                    )}
                {availableProducts.wallboxes &&
                    availableProducts.wallboxes.length > 0 && (
                        <ExpandableIcon
                            onIconClick={handleIconClick}
                            expanded={isExpanded}
                            icon={'wallbox'}
                            hasValue={!!products?.wallboxes}
                        >
                            <ProductSelector
                                productCategory={ProductTypes.WALLBOXES}
                                className={styles.productSelector}
                            />
                        </ExpandableIcon>
                    )}
            </Stack>
        </Stack>
    );
};

export default SalesTools;
