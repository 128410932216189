import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DropdownProps } from 'semantic-ui-react';
import { NO_PRODUCT } from '../../common/constants';
import { getUnmodifiedProducts } from '../../common/functions';
import { findProductById } from '../../common/functions/products';
import {
    getBrandMatchingInverter,
    getProductsWithSameBrand,
} from '../../common/helpers';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import useOpportunity from '../../common/hooks/useOpportunity';
import useProducts from '../../common/hooks/useProducts';
import useUserRole from '../../common/hooks/useUserRole';
import {
    BasicQuoteProduct,
    Product,
    ProductTypes,
    RootState,
    SelectItem,
    UserRoles,
} from '../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    updateProduct,
} from '../../features/designerQuote/designerQuoteActions';
import Header from '../Header';
import Select from '../Select';
import { useAppDispatch } from '../../common/hooks';

type Props = {
    productCategory: ProductTypes;
    productOptionsType?: ProductTypes;
    hasEmptyOption?: boolean;
    direction?: 'up' | 'down';
    className?: string;
    secondInstance?: boolean;
    isDisabled?: boolean;
};

const productsToSelectItems = (
    products: Product[],
    keySuffix = ''
): SelectItem[] => {
    return products.map((product) => {
        return {
            key: product.id + keySuffix,
            value: product.id,
            text: product.name,
        };
    });
};

const productsExcludingRecommended = (
    products: Product[],
    recommendedProducts: Product[]
) => {
    return products.filter((product) =>
        recommendedProducts.every(
            (recommendedProduct) => recommendedProduct.id !== product.id
        )
    );
};

const ProductSelector: FunctionComponent<Props> = ({
    productCategory,
    productOptionsType = productCategory,
    direction,
    hasEmptyOption = true,
    className,
    secondInstance = false,
    isDisabled = false,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['productsWizard']);
    const selectNone = t('no' + productOptionsType);

    const [products] = useProducts(productOptionsType);
    const [recommendedInverters] = useProducts(
        !secondInstance
            ? ProductTypes.RECOMMENDED_INVERTERS
            : ProductTypes.SECOND_RECOMMENDED_INVERTERS
    );

    const [options, setOptions] = useState<SelectItem[]>([]);
    const opportunity = useOpportunity();
    const designerQuote = useDesignerQuote();
    const userRole = useUserRole();
    const sfProducts = useDesignerQuote().salesforce.Products;
    const activeProduct = sfProducts?.[productCategory] as BasicQuoteProduct;
    const inverter = useSelector<RootState, Product | null>(
        (state) =>
            state.designerQuote.salesforce.Products
                ?.inverter as BasicQuoteProduct | null
    );

    const setNoProduct = (productType: ProductTypes) => {
        dispatch(
            dispatchAndSaveToLocalStorage(
                updateProduct({
                    productType: productType,
                    product: null,
                })
            )
        );
    };

    useEffect(() => {
        if (!products) return;

        if (
            (productCategory === ProductTypes.BATTERY ||
                productCategory === ProductTypes.WALLBOXES) &&
            inverter &&
            (inverter.productBrand || inverter.compatibleWith)
        ) {
            const brands = getBrandMatchingInverter(
                inverter.productBrand || '',
                productCategory
            );
            const filteredProducts: { [key: string]: Product } = {};
            (inverter.compatibleWith || []).forEach((compatibleProduct) => {
                if (compatibleProduct.category === productCategory) {
                    filteredProducts[compatibleProduct.id] = compatibleProduct;
                }
            });
            if (brands) {
                products
                    .filter((product) =>
                        brands.some((brand) => brand === product.productBrand)
                    )
                    .forEach((product) => {
                        if (
                            !Object.keys(filteredProducts).includes(product.id)
                        ) {
                            filteredProducts[product.id] = product;
                        }
                    });
                setOptions(
                    productsToSelectItems(Object.values(filteredProducts))
                );
            }
            if (
                activeProduct?.id &&
                !Object.keys(filteredProducts).includes(activeProduct.id)
            ) {
                setNoProduct(productCategory);
            }
        } else {
            setOptions(productsToSelectItems(products));
        }

        if (
            productCategory === ProductTypes.INVERTER ||
            productCategory === ProductTypes.SECOND_INVERTER
        ) {
            let recommendedProducts = recommendedInverters;
            let filteredProducts: Product[] | undefined = products;
            if (
                productCategory === ProductTypes.SECOND_INVERTER &&
                inverter &&
                inverter.productBrand
            ) {
                recommendedProducts = getProductsWithSameBrand(
                    recommendedInverters,
                    inverter.productBrand
                );

                filteredProducts = getProductsWithSameBrand(
                    products,
                    inverter.productBrand
                );
            }
            if (
                productCategory === ProductTypes.SECOND_INVERTER &&
                activeProduct?.id &&
                !filteredProducts.includes(activeProduct)
            ) {
                setNoProduct(productCategory);
            }

            const recommendedOptions = recommendedProducts
                ? productsToSelectItems(recommendedProducts)
                : [];

            const recommendedOptionsWithLabel = [
                {
                    key: 'recommended',
                    value: '',
                    text: '',
                    content: <Header children={t('recommended')} />,
                    disabled: true,
                },
                ...recommendedOptions,
            ];

            const plannerChoiceInverter = getUnmodifiedProducts(
                opportunity,
                designerQuote.salesforce.id
            )?.[productCategory];

            const selectedByPlannerOption =
                userRole !== UserRoles.planner && plannerChoiceInverter
                    ? [
                          {
                              key: 'planner-choice',
                              value: '',
                              text: '',
                              content: <Header children={t('plannerChoice')} />,
                              disabled: true,
                          },
                          ...productsToSelectItems(
                              [plannerChoiceInverter],
                              '-planner'
                          ),
                      ]
                    : [];

            const othersLabel = {
                key: 'others',
                value: '',
                text: '',
                content: <Header children={t('others')} />,
                disabled: true,
            };

            setOptions(
                recommendedProducts
                    ? [
                          ...recommendedOptionsWithLabel,
                          ...selectedByPlannerOption,
                          othersLabel,
                          ...productsToSelectItems(
                              productsExcludingRecommended(
                                  filteredProducts,
                                  recommendedProducts
                              )
                          ),
                      ]
                    : [
                          ...selectedByPlannerOption,
                          othersLabel,
                          ...productsToSelectItems(filteredProducts),
                      ]
            );
        }

        if (hasEmptyOption) {
            setOptions((options) => {
                return [
                    {
                        value: NO_PRODUCT,
                        text: selectNone,
                    },
                    ...options,
                ];
            });
        }
    }, [inverter, products, recommendedInverters]);

    const onChange = (
        event: React.SyntheticEvent<HTMLElement> | undefined,
        data: DropdownProps
    ) => {
        if (!products) return;

        if (data.value === NO_PRODUCT) {
            setNoProduct(productCategory);
        } else {
            const product = findProductById(products, data.value as string);
            if (product) {
                dispatch(
                    dispatchAndSaveToLocalStorage(
                        updateProduct({
                            productType: productCategory,
                            product: { ...product },
                        })
                    )
                );
            }
        }
    };

    return (
        <Select
            options={options}
            onChange={onChange}
            direction={direction}
            className={className}
            disabled={isDisabled}
            value={activeProduct ? activeProduct.id : NO_PRODUCT}
        />
    );
};

export default ProductSelector;
