import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    CheckboxProps,
    Form as SemanticForm,
    FormInputProps,
} from 'semantic-ui-react';
import {
    FEED_IN_TARRIF_OVER_10_KWP,
    FEED_IN_TARRIF_UNDER_10_KWP,
} from '../../common/constants';
import {
    getFeedInTariff,
    getTotalSystemCapacityInKWP,
    hasYieldReduction,
} from '../../common/functions';
import { isBlank, valueInBetween } from '../../common/functions/validations';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import useRecommendedInverters from '../../common/hooks/useRecommendedInverters';
import useSelfConsumption from '../../common/hooks/useSelfConsumption';
import useSystemCharacteristics from '../../common/hooks/useSystemCharacteristics';
import { useUi } from '../../common/hooks/useUi';
import useUserRole from '../../common/hooks/useUserRole';
import {
    DesignerQuoteState,
    MinAndMax,
    RoiValueId,
    RootState,
    SalesRoiValueId,
    StringIndexable,
    UiState,
    UserRoles,
} from '../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    updateManualROIValueInPlanning,
    updateROIValue,
} from '../../features/designerQuote/designerQuoteActions';
import {
    dismissNotification,
    newNotification,
} from '../../features/notifications/notificationsActions';
import {
    hideRecalculateButton,
    toggleEnergyFieldsVisibility,
} from '../../features/ui/uiActions';
import i18n from '../../i18n';
import FormInput from '../FormInput';
import Icon from '../Icon';
import RecalculateButton from '../RecalculateButton';
import styles from './ROIForm.module.scss';
import { useAppDispatch } from '../../common/hooks';

const roiValuesMinMaxAndStep: {
    [key in RoiValueId]: { step?: number } & MinAndMax;
} = {
    annualYieldHours: { min: 1, max: 10000 },
    reducedAnnualYieldHours: { min: 1, max: 10000 },
    selfConsumptionKwh: { min: 1, max: 100000 },
    monthlyElectricityCosts: { min: 0.01, max: 10000, step: 0.01 },
    electricityCostKwh: { min: 0, max: 100, step: 0.01 },
    selfConsumptionRate: { min: 1, max: 100 },
    electricityBoughtAtInitialRate: { min: 0.01, max: 10, step: 0.01 },
    degreeOfSelfSufficiency: { min: 0, max: 100, step: 0.01 },
};

const salesRoiValues: SalesRoiValueId[] = [
    'annualYieldHours',
    'reducedAnnualYieldHours',
    'monthlyElectricityCosts',
    'selfConsumptionRate',
    'degreeOfSelfSufficiency',
    'selfConsumptionKwh',
];

const ROIForm: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const designerQuote = useDesignerQuote();
    const currentUserRole = useUserRole();
    const hideEnergyFields = useSelector<RootState, UiState>(
        (state) => state.ui
    ).hideEnergyFields;

    const { quantity: panelQuantity, power: panelPower } = designerQuote
        .salesforce.Products?.panels || {
        quantity: 0,
        power: 0,
    };

    const isSales = currentUserRole === UserRoles.sales;

    const constructInputProps = (
        designerQuote: DesignerQuoteState
    ): { [key in RoiValueId]: FormInputProps } => {
        let props = {
            annualYieldHours: {
                placeholder: i18n.t('roi:annualYieldHours'),
                value: designerQuote.salesforce.annualYieldHours || '',
                label: {
                    basic: true,
                    content: <span className="unit">kWh / kWp</span>,
                },
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.annualYieldHours
                ),
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.annualYieldHours
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.annualYieldHours,
                disabled: hasYieldReduction(designerQuote.planning),
            },
            reducedAnnualYieldHours: {},
            selfConsumptionKwh: {
                placeholder: i18n.t('roi:annualPowerConsumptionKwh'),
                value: designerQuote.salesforce.selfConsumptionKwh || '',
                disabled: true,
                label: {
                    basic: true,
                    content: <span className="unit">kWh</span>,
                },
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.selfConsumptionKwh
                ),
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.selfConsumptionKwh
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.selfConsumptionKwh,
            },
            monthlyElectricityCosts: {
                placeholder: i18n.t('roi:monthlyElectricityCosts'),
                value: designerQuote.salesforce.monthlyElectricityCosts || '',
                label: {
                    basic: true,
                    content: <span className="unit">€</span>,
                },
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.monthlyElectricityCosts
                ),
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.monthlyElectricityCosts
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.monthlyElectricityCosts,
            },
            electricityCostKwh: {
                placeholder: i18n.t('roi:electricityCostKwh'),
                value: designerQuote.salesforce.electricityCostKwh || '',
                label: {
                    basic: true,
                    content: <span className="unit">€ / kWh</span>,
                },
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.electricityCostKwh
                ),
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.electricityCostKwh
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.electricityCostKwh,
            },
            selfConsumptionRate: {
                placeholder: i18n.t('roi:selfConsumptionRate'),
                value: designerQuote.salesforce.selfConsumptionRate || '',
                label: {
                    basic: true,
                    content: <span className="unit">%</span>,
                },
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.selfConsumptionRate
                ),
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.selfConsumptionRate
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.selfConsumptionRate,
            },
            electricityBoughtAtInitialRate: {
                placeholder: i18n.t('roi:electricityBoughtAtInitialRate'),
                value:
                    designerQuote.salesforce.electricityBoughtAtInitialRate ||
                    '',
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.electricityBoughtAtInitialRate
                ),
                label: {
                    basic: true,
                    content: <span className="unit">€</span>,
                },
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.electricityBoughtAtInitialRate
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.electricityBoughtAtInitialRate,
            },
            degreeOfSelfSufficiency: {
                id: 'degreeOfSelfSufficiency',
                placeholder: i18n.t('roi:degreeOfSelfSufficiency'),
                disabled: true,
                value: designerQuote.salesforce.degreeOfSelfSufficiency || '',
                errorMessage: i18n.t(
                    'validations:valuesInBetween',
                    roiValuesMinMaxAndStep.degreeOfSelfSufficiency
                ),
                label: {
                    basic: true,
                    content: <span className="unit">%</span>,
                },
                validation: (val: string) =>
                    valueInBetween(
                        val,
                        roiValuesMinMaxAndStep.degreeOfSelfSufficiency
                    ) || isBlank(val),
                type: 'number',
                input: roiValuesMinMaxAndStep.degreeOfSelfSufficiency,
            },
        };

        if (
            designerQuote.salesforce.reducedAnnualYieldHours &&
            designerQuote.salesforce.annualYieldHours &&
            designerQuote.salesforce?.reducedAnnualYieldHours <
                designerQuote.salesforce?.annualYieldHours
        )
            props = {
                ...props,
                reducedAnnualYieldHours: {
                    placeholder: i18n.t('roi:reducedAnnualYieldHours'),
                    value:
                        designerQuote.salesforce.reducedAnnualYieldHours || '',
                    label: {
                        basic: true,
                        content: <span className="unit">kWh / kWp</span>,
                    },
                    type: 'info',
                },
            };

        return props;
    };

    const [roiInputProps, setRoiInputProps] = useState(
        constructInputProps(designerQuote)
    );

    const handleBlur = (
        event: React.FocusEvent,
        data: FormInputProps | CheckboxProps
    ) => {
        const id = data.id as RoiValueId | undefined;
        if (id === undefined) return;

        let roiValue;
        const { type } = data;
        switch (type) {
            case 'checkbox':
                if (!roiInputProps[id].validation(data.checked)) return;
                roiValue = data.checked;
                break;
            default:
                if (!roiInputProps[id].validation(data.value)) return;
                roiValue = parseFloat(data.value);
        }

        dispatch(updateROIValue({ roiData: { [id]: roiValue } }));
        if (id === 'selfConsumptionRate') {
            dispatch(
                dispatchAndSaveToLocalStorage(
                    updateManualROIValueInPlanning({
                        roiData: {
                            selfConsumptionRate: roiValue,
                        },
                    })
                )
            );
        }
    };

    useEffect(() => {
        setRoiInputProps(constructInputProps(designerQuote));
    }, [
        designerQuote.salesforce.selfConsumptionKwh,
        designerQuote.salesforce.annualYieldHours,
        designerQuote.salesforce.degreeOfSelfSufficiency,
        designerQuote.salesforce.reducedAnnualYieldHours,
        designerQuote.salesforce.Products?.grounding,
        designerQuote.salesforce.Products?.battery,
        designerQuote.salesforce.electricityBoughtAtInitialRate,
    ]);

    useSelfConsumption();

    useEffect(() => {
        const {
            reducedAnnualYieldHours,
            selfConsumptionRate,
            selfConsumptionKwh,
            annualYieldHours,
        } = designerQuote.salesforce;

        const systemCapacity = getTotalSystemCapacityInKWP(
            panelQuantity || 0,
            panelPower || 0
        );

        if (
            (reducedAnnualYieldHours ?? annualYieldHours) &&
            selfConsumptionRate &&
            selfConsumptionKwh &&
            systemCapacity
        ) {
            const independence =
                (selfConsumptionRate *
                    (systemCapacity *
                        (reducedAnnualYieldHours ?? annualYieldHours)!)) /
                selfConsumptionKwh;

            dispatch(
                updateROIValue({
                    roiData: {
                        degreeOfSelfSufficiency:
                            Math.round(independence * 100 + Number.EPSILON) /
                            100,
                    },
                })
            );
        }
    }, [
        designerQuote.salesforce.reducedAnnualYieldHours,
        designerQuote.salesforce.selfConsumptionRate,
        designerQuote.salesforce.selfConsumptionKwh,
        panelPower,
        panelQuantity,
    ]);

    useEffect(() => {
        // calculate current feed-In Tariff
        const totalPower = (panelQuantity! * panelPower!) / 1000;
        const remainderPowerOver10 = totalPower - 10;

        const feedInTariff =
            remainderPowerOver10 > 0
                ? getFeedInTariff(
                      remainderPowerOver10,
                      10,
                      FEED_IN_TARRIF_OVER_10_KWP,
                      FEED_IN_TARRIF_UNDER_10_KWP
                  )
                : FEED_IN_TARRIF_UNDER_10_KWP;

        dispatch(
            updateROIValue({
                roiData: {
                    electricityBoughtAtInitialRate:
                        Math.round(feedInTariff * 1000 + Number.EPSILON) / 1000,
                },
            })
        );
    }, [panelQuantity, panelPower]);

    useSystemCharacteristics();

    let inputProps: StringIndexable = {};
    if (isSales) {
        salesRoiValues.forEach((value) => {
            inputProps[value] = roiInputProps[value];
        });
    } else {
        inputProps = roiInputProps;
    }

    const handleShowHideToggle = () => {
        dispatch(toggleEnergyFieldsVisibility());
    };

    const [{ showRecalculateButton }] = useUi();

    useRecommendedInverters();

    useEffect(() => {
        if (!isSales && showRecalculateButton) {
            const notification = newNotification({
                type: 'info',
                icon: 'alert',
                message: '',
                details: 'Die Werte sind aktualisiert worden…',
                onDismiss: () => {
                    dispatch(hideRecalculateButton());
                },
            });
            notification.payload.children = (
                <RecalculateButton
                    iconSize={'small'}
                    iconStrokeWidth={12}
                    style={{ margin: '0 1.5em 0 .5em' }}
                    onClick={() => {
                        dispatch(dismissNotification(notification.payload));
                    }}
                />
            );
            dispatch(notification);
        }
    }, [showRecalculateButton]);

    return (
        <SemanticForm>
            {Object.keys(inputProps).map((key: string) => {
                if (inputProps[key].value === undefined) {
                    return null;
                }
                const hasDropDown = isSales && key === 'selfConsumptionKwh';

                const formInput = (
                    <FormInput
                        key={key + '-input'}
                        onBlur={handleBlur}
                        formInput={inputProps[key]}
                        id={key}
                        sales={isSales}
                        className={styles.form}
                    />
                );
                return !hasDropDown ? (
                    formInput
                ) : (
                    <div
                        key={key + '-div'}
                        onClick={handleShowHideToggle}
                        className={styles.inputWithButtonOrCheckbox}
                    >
                        {formInput}
                        <Icon
                            className={styles.toggleIcon}
                            filled
                            stroke={16}
                            id={hideEnergyFields ? 'plus' : 'minus'}
                            key={key + '-icon'}
                            size={'small'}
                        />
                    </div>
                );
            })}
        </SemanticForm>
    );
};

export default ROIForm;
