import {
    hasProductSameQuantity,
    isProductChecked,
    isValidProduct,
} from '../common/functions';
import productTypeOrder from '../common/sortOrder';
import {
    ChangesStack,
    Product,
    ProductTypes,
    QuoteLineItem,
    SalesforceProductsState,
} from '../common/types';

export const productTypesToConsider = [
    ProductTypes.INVERTER,
    ProductTypes.SECOND_INVERTER,
    ProductTypes.PANEL,
    ProductTypes.BATTERY,
    ProductTypes.WALLBOXES,
    ProductTypes.ELECTRICAL_CABINETS,
    ProductTypes.ELECTRICAL_MATERIAL,
    ProductTypes.SCAFFOLDING,
    ProductTypes.ELECTRICAL_CABINET_UPGRADES,
    ProductTypes.ADDITIONAL_LABOR,
    ProductTypes.ELECTRICAL_LABOR,
    ProductTypes.MOUNTING_SYSTEM,
    ProductTypes.GROUNDING,
    ProductTypes.SCAFFOLDING_ADD_ONS,
    ProductTypes.MOUNTING_SYSTEM_ACCESSORIES,
    ProductTypes.HEM_GATEWAY,
];

export const findQuoteLineItem = (
    product: Product,
    quoteLineItems: QuoteLineItem[]
) => {
    // Find old QuoteLineItem
    const quoteLineItem = quoteLineItems.find(
        (item) => item.Product2Id.toLowerCase() === product.id.toLowerCase()
    );

    if (!quoteLineItem) throw new Error('Cant find the QuotelineItem.');
    return quoteLineItem;
};

// Add the right sortOrder and quantity to the dependency
export const enrichDependency = (
    productType: ProductTypes,
    dependency: Product,
    parentProduct: Product,
    panelAmount?: number
) => {
    switch (productType) {
        case ProductTypes.MOUNTING_SYSTEM:
            return {
                ...dependency,
                quantity: parentProduct.quantity,
                sortOrder: productTypeOrder.MONTAGE_SERVICE,
            };
        case ProductTypes.WALLBOXES:
            return {
                ...dependency,
                quantity: parentProduct.quantity,
                sortOrder: productTypeOrder.WALLBOX_SERVICE,
            };
        case ProductTypes.INVERTER:
            return dependency.isOptimizer
                ? {
                      ...dependency,
                      quantity: panelAmount,
                      sortOrder: productTypeOrder.OPTIMIZER,
                  }
                : {
                      ...dependency,
                      sortOrder: productTypeOrder.INVERTER_ACCESSORY,
                  };
        case ProductTypes.ELECTRICAL_CABINET_UPGRADES:
            return {
                ...dependency,
                sortOrder: productTypeOrder.electricalCabinetUpgrades,
            };
        case ProductTypes.GROUNDING:
            return {
                ...dependency,
                sortOrder: productTypeOrder.grounding,
            };
        case ProductTypes.MOUNTING_SYSTEM_ACCESSORIES:
            return {
                ...dependency,
                quantity: parentProduct.quantity,
                sortOrder: productTypeOrder.mountingSystemAccessories,
            };
        default:
            return dependency;
    }
};

export const productsDiff = (
    oldProducts: SalesforceProductsState,
    newProducts: SalesforceProductsState,
    productType:
        | ProductTypes.ELECTRICAL_CABINET_UPGRADES
        | ProductTypes.MOUNTING_SYSTEM,
    quoteLineItems: QuoteLineItem[]
): [Product[], string[]] => {
    const oldArray = oldProducts[productType] as Product[];
    const newArray = newProducts[productType] as Product[];
    const oldIsAcceptable =
        oldArray && oldArray.every((up) => isValidProduct(up));
    const newIsAcceptable =
        newArray && newArray.every((up) => isValidProduct(up));

    const toDelete: string[] = [];
    const toAdd: Product[] = [];

    if (oldIsAcceptable && !newIsAcceptable) {
        oldArray.forEach((product) => {
            toDelete.push(findQuoteLineItem(product, quoteLineItems).Id);
            product.dependencies?.forEach((dep) => {
                toDelete.push(findQuoteLineItem(dep, quoteLineItems).Id);
            });
        });
    } else if (!oldIsAcceptable && newIsAcceptable) {
        newArray.forEach((product) => {
            toAdd.push({
                ...product,
                sortOrder: productTypeOrder[productType],
            });
            product.dependencies?.forEach((dep) =>
                toAdd.push(enrichDependency(productType, dep, product))
            );
        });
    } else if (oldIsAcceptable && newIsAcceptable) {
        oldArray.forEach((product) => {
            if (!isProductChecked(product, productType, newProducts)) {
                toDelete.push(findQuoteLineItem(product, quoteLineItems).Id);
                // Also delete the dependencies
                product.dependencies?.forEach(() =>
                    toDelete.push(findQuoteLineItem(product, quoteLineItems).Id)
                );
            } else {
                if (
                    productType === ProductTypes.MOUNTING_SYSTEM &&
                    !hasProductSameQuantity(product, productType, newProducts)
                ) {
                    // It's already checked but has a different quantity: delete the old
                    toDelete.push(
                        findQuoteLineItem(product, quoteLineItems).Id
                    );
                    product.dependencies?.forEach((dep) =>
                        toDelete.push(findQuoteLineItem(dep, quoteLineItems).Id)
                    ); // delete dependencies
                }
            }
        });
        newArray.forEach((product) => {
            if (!isProductChecked(product, productType, oldProducts)) {
                toAdd.push({
                    ...product,
                    sortOrder: productTypeOrder[productType],
                });
                // Also add the dependencies
                product.dependencies?.forEach((dep) =>
                    toAdd.push(enrichDependency(productType, dep, product))
                );
            } else {
                if (
                    productType === ProductTypes.MOUNTING_SYSTEM &&
                    !hasProductSameQuantity(product, productType, oldProducts)
                ) {
                    // It's already checked but has a different quantity: add the new
                    toAdd.push({
                        ...product,
                        sortOrder: productTypeOrder[productType],
                    });
                    // add dependencies
                    product.dependencies?.forEach((dep) =>
                        toAdd.push(enrichDependency(productType, dep, product))
                    );
                }
            }
        });
    } else {
        //nothing
    }
    return [toAdd, toDelete];
};

export const findChanges = (
    stack: ChangesStack,
    productType: ProductTypes
): ChangesStack => {
    // Array of Products
    if (
        productType === ProductTypes.ELECTRICAL_CABINET_UPGRADES ||
        productType === ProductTypes.MOUNTING_SYSTEM
    ) {
        const [toAdd, toDelete] = productsDiff(
            stack.oldProducts,
            stack.newProducts,
            productType,
            stack.quoteLineItems
        );
        return {
            ...stack,
            toAdd: [...stack.toAdd, ...toAdd],
            toDelete: [...stack.toDelete, ...toDelete],
        };
    } else {
        // One Product
        const oldProduct = stack.oldProducts[productType] as Product; // checked with isValidProduct
        const newProduct = stack.newProducts[productType] as Product; // checked with isValidProduct
        const isOldProductValid = isValidProduct(oldProduct);
        const isNewProductValid = isValidProduct(newProduct);
        const newNumberOfPanels =
            stack.newProducts[ProductTypes.PANEL].quantity;
        const oldNumberOfPanels =
            stack.oldProducts[ProductTypes.PANEL].quantity;

        if (!isOldProductValid && !isNewProductValid) return stack;
        if (
            isOldProductValid &&
            isNewProductValid &&
            oldProduct.id === newProduct.id &&
            ((!oldProduct.quantity && !newProduct.quantity) ||
                oldProduct.quantity === newProduct.quantity)
        ) {
            if (
                productType === ProductTypes.INVERTER &&
                oldNumberOfPanels !== newNumberOfPanels &&
                newProduct.dependencies &&
                newProduct.dependencies.length > 0
            ) {
                // update the optimizer
                const optimizer = newProduct.dependencies.find(
                    (dep) => dep.isOptimizer
                );
                optimizer &&
                    stack.toUpdate.push({
                        id: findQuoteLineItem(optimizer, stack.quoteLineItems)
                            .Id,
                        Quantity: newNumberOfPanels || 1,
                    });
            }
            return stack; // Products are the same
        }

        if (!isOldProductValid && isNewProductValid) {
            stack.toAdd.push({
                ...newProduct,
                sortOrder: productTypeOrder[productType],
            });
            newProduct.dependencies?.forEach((dep) =>
                stack.toAdd.push(
                    enrichDependency(
                        productType,
                        dep,
                        newProduct,
                        newNumberOfPanels
                    )
                )
            );
        } else {
            const quoteLineItem = findQuoteLineItem(
                oldProduct,
                stack.quoteLineItems
            );

            if (oldProduct && !isNewProductValid) {
                stack.toDelete.push(quoteLineItem.Id);
                oldProduct.dependencies?.forEach((dep) => {
                    stack.toDelete.push(
                        findQuoteLineItem(dep, stack.quoteLineItems).Id
                    );
                });
            } else if (oldProduct.id === newProduct.id) {
                if (
                    oldProduct!.quantity !== 0 &&
                    newProduct!.quantity === 0 // Usecase: scaffoling
                ) {
                    stack.toDelete.push(quoteLineItem.Id);
                } else {
                    stack.toUpdate.push({
                        id: quoteLineItem.Id,
                        Quantity: newProduct.quantity || 1,
                    });
                }
            } else {
                // Different product
                stack.toDelete.push(quoteLineItem.Id);
                oldProduct.dependencies?.forEach((dep) => {
                    stack.toDelete.push(
                        findQuoteLineItem(dep, stack.quoteLineItems).Id
                    );
                });
                stack.toAdd.push({
                    ...newProduct,
                    sortOrder: productTypeOrder[productType],
                });
                newProduct.dependencies?.forEach((dep) =>
                    stack.toAdd.push(
                        enrichDependency(
                            productType,
                            dep,
                            newProduct,
                            newNumberOfPanels
                        )
                    )
                );
            }
        }
    }
    return stack;
};

export const getProductChanges = (p: {
    oldProducts: SalesforceProductsState | undefined;
    newProducts: SalesforceProductsState | undefined;
    quoteLineItems: QuoteLineItem[];
    productTypesToUpdate: ProductTypes[];
    stackFillFunction: (
        stack: ChangesStack,
        productType: ProductTypes
    ) => ChangesStack;
}): ChangesStack => {
    const startStack = {
        toAdd: [],
        toDelete: [],
        toUpdate: [],
        oldProducts: p.oldProducts,
        newProducts: p.newProducts,
        quoteLineItems: p.quoteLineItems,
    } as ChangesStack;
    return !p.oldProducts || !p.newProducts
        ? startStack
        : p.productTypesToUpdate.reduce(
              (value, productType) => p.stackFillFunction(value, productType),
              startStack
          );
};
